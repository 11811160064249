.Card.ant-card { /* Overrides ant design */
    margin: 20px auto;
    max-width: 38rem;
}

.Card.ant-card.Card--borderless {
    border: none;
    padding: 0;
    margin: 0 auto;
    background: transparent;
}

.Card.ant-card.Card--borderless .ant-card-body {
    padding: 0;
    margin: 0;
}