.Header {
    background: #126265;
    height: 52px;
    position: relative;
    margin-bottom: 30px;
    color: white;
}

.Header-logo {
    position: absolute;
    left: 50%;
    transform: translateX(calc(90px / -2))
}

.Header-menuButton {
    font-size: 32px;
    margin: 10px;
    cursor: pointer;
}