.Drawer {
    position: relative;
    font-size: 18px;
}

.Drawer .Drawer-list--bottom {
    position: absolute;
    bottom: 0;
}

.Drawer .Drawer-list--bottom li {
    font-size: 14px;
    margin-bottom: 2px;
}

.Drawer ul {
    padding: 0;
}

.Drawer li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}

.Drawer a {
    color: black;
}

.Drawer a:hover {
    text-decoration: underline;
}