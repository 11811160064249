.Question-button {
    float: right;
}

.Question-choice--success, .Question-choice--failure {
    font-weight: bolder;
}

.Question-choice--success .ant-radio-disabled + span, .Question-choice--success .ant-checkbox-disabled + span {
    color: green;
}

.Question-choice--failure .ant-radio-disabled + span, .Question-choice--failure .ant-checkbox-disabled + span {
    color: red;
}